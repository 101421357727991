form {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	@media (min-width: 992px) {
		width: 350px;
	}

	fieldset {
		border: none;
		flex-direction: column;
		margin-bottom: 10px;

		input,
		textarea {
			width: 100%;
			border-radius: 2px;
			border: 1px solid darken(rgb(236, 236, 236), 20%);
		}
		label {
			display: flex;
			align-self: flex-start;
			width: 100%;
		}
		input {
			padding: 10px 5px;
			margin-top: 5px;
		}
		textarea {
			padding: 10px 5px;
			resize: none;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 13px;
			height: 175px;
			margin-top: 5px;
		}
	}
	button {
		padding: 10px 10px;
		font-size: 16px;
		background-color: #00d689;
		color: black;
		border: solid 1px #00d689;
		border-radius: 2px;

		&:hover {
			background-color: #008353;
			border: solid 1px #008353;
		}
	}
}
