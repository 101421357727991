.navbar {
	position: fixed;
	height: 64px;
	width: 100vw;
	background-color: black;
	z-index: 1;
	@media (min-width: 992px) {
		position: fixed;
		top: 0;
		z-index: 1;
		width: 100%;
		display: flex;
		max-width: 100%;
		height: 64px;
		overflow: hidden;
		background: black;
		font-family: "Varela Round", sans-serif;
	}

	ul.navbar-items {
		display: none;
		position: absolute;
		height: 100vh;
		width: 100vw;
		flex-direction: column;
		// transform: translate(-50%, -50%);
		// top: 50%;
		// left: 50%;
		@media (min-width: 992px) {
			display: flex;
			flex-direction: row;
			height: 100%;
			list-style: none;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
		&.mobile {
			display: flex;
			background: black;
			justify-content: center;
			align-items: center;
			li {
				display: block;
			}
		}
		li {
			a {
				color: #f2f2f2;
				text-align: center;
				text-decoration: none;
				font-size: 32px;
				@media (min-width: 992px) {
					padding: 1rem;
					margin: 0 1rem;
					font-size: 20px;
				}

				span {
					border-bottom: 1px solid transparent;
				}
				&.is-active {
					color: rgb(255, 221, 159);
					span {
						border-bottom: 1px solid rgb(255, 221, 159);
					}
				}
				&:hover {
					color: rgb(255, 221, 159);
				}
			}
			&.githubholder {
				@media (min-width: 992px) {
					position: absolute;
					right: 5px;
				}
			}
		}
	}
	/* background-color: #333;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-content: space-around; */
	.logoholder {
		position: absolute;
		top: 7px;

		img {
			width: 50px;
			height: 50px;
			margin-left: 7px;
		}
	}
	> div:not(.logoholder):not(.githubholder) {
		display: flex;
		list-style: none;
		width: 100%;
		justify-content: center;
	}
}

.navbar .test-div {
	@media (min-width: 992px) {
		display: none;
	}
}
.burger {
	position: absolute;
	top: 12px;
	right: 20px;
	cursor: pointer;
	@media (min-width: 992px) {
		display: none;
	}
	> div {
		width: 35px;
		height: 5px;
		background-color: white;
		margin: 6px 0;
		transition: 0.4s;
	}
	&.active {
		.line1 {
			-webkit-transform: rotate(-45deg) translate(-8px, 7px);
			transform: rotate(-45deg) translate(-8px, 7px);
		}
		.line2 {
			opacity: 0;
		}
		.line3 {
			-webkit-transform: rotate(45deg) translate(-8px, -8px);
			transform: rotate(45deg) translate(-8px, -8px);
		}
	}
}

@media (max-width: 991px) {
	.burger {
		display: block;
	}
}

.mobile-active {
	overflow: hidden;
}
