.CV-top {
	@media (min-width: 992px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.about {
	display: flex;
	flex-direction: column;
	width: 50%;
	font-size: 1rem;

	@media (min-width: 992px) {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-self: normal;
		width: auto;
	}

	&__box {
		> h2 {
			text-align: center;
			padding-top: 20px;
		}
		@media (min-width: 992px) {
			width: auto;
			align-self: flex-end;
			text-align: center;
		}
	}
}

.about__right > h2 {
	text-align: center;
	padding-top: 15px;
}

.image1 {
	position: relative;
}

.image2 {
	position: relative;
}

.image__utdanning {
	display: block;
	justify-content: space-between;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.image__jobberfaring {
	display: block;
	justify-content: space-between;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.image__overlay {
	display: flex;
	flex-direction: column;
	opacity: 1;
	@media (min-width: 992px) {
		position: absolute;
		z-index: auto;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid rgb(236, 236, 236);
		background: rgba(0, 0, 0, 0.6);
		color: rgb(255, 255, 255);
		font-family: "Oswald", sans-serif;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transition: opacity 0.25s;
		> * {
			transform: translateY(50px);
			transition: transform 0.5s;
		}
		&:hover {
			opacity: 1;
			> * {
				transform: translateY(0);
			}
		}
	}
}

.image__overlay--primary {
	opacity: 1;
	@media (min-width: 992px) {
		background: rgb(32, 31, 31);
		opacity: 0;
	}
}

.image__description {
	text-align: center;
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: center;
		font-size: 1.75em;
		margin-top: 0.25em;
	}
}

.image__description2 {
	text-align: center;
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: center;
		font-size: 1.25em;
		margin-top: 0.25em;
	}
}

.image__header2 {
	text-align: center;
	color: rgb(255, 128, 70);
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: center;
		font-size: 1.25em;
		padding-bottom: 15px;
	}
}

.image__header {
	text-align: center;
	color: rgb(255, 128, 70);
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: left;
		font-size: 1.25em;
		padding-bottom: 15px;
	}
}

.image__subheader {
	text-align: center;
	color: rgb(255, 128, 70);
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: left;
		font-size: 1em;
		padding-bottom: 10px;
	}
}

.image__year {
	text-align: center;
	color: rgb(255, 255, 255);
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: left;
		display: flex;
		font-size: 0.75em;
		margin-top: 0.25em;
		align-items: center;
	}
}

.image__year > p {
	@media (min-width: 992px) {
		width: 85px;
	}
}

.image__subtext {
	text-align: center;
	color: rgb(255, 255, 255);
	font-size: 1em;
	@media (min-width: 992px) {
		text-align: left;
		padding-left: 30px;
		font-size: 1.25em;
	}
}
